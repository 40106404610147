var clicktimeout = null;
var searchtimeout = null;
var navtimeout = null;
var grid = null;

$(function() {
    console.log('INIT FE');
    
    initNav();
    initGrid();
    initMobileHover();
    initToggleNext();
    initUncrypt();
    initFilter();
    initNews();
    initSlider();
    initNewsSearch();
    initSearch();
    dvf.initLazyload();
});

function initNav(){ 
    $('.nav-control').each(function(){
        if(!$(this).hasClass('has-control')){
            $(this).on('touchstart', function(e){
                $(this).click();
                console.log('touch');
                e.preventDefault();
                return true;
            });
            $(this).mouseover(function(){
                if(!$('nav').hasClass('open') && !clicktimeout){
                    $('nav').addClass('open');
                }
                console.log('mouseover')
            });
            $(this).click(function(){
                if($('nav').hasClass('open')){
                    $('nav').removeClass('open');
                    clicktimeout = setTimeout(function () {
                        // do nothing
                        clicktimeout = null;
                    }, 500);
                } else {
                    $('nav').addClass('open');
                }
            });
            $(this).addClass('has-control')
        }
    });
    $('nav').on('mouseout',function(){
        navtimeout = window.setTimeout(function(){
            $('nav').removeClass('open');
        }, 300);
    });
    $('nav').on('mouseover',function(){
        window.clearTimeout(navtimeout);
    });
}
function initGrid(){ 
    grid = $('.grid').masonry({
      itemSelector: '.news',
    });
    grid.imagesLoaded().progress( function() {
        grid.masonry('layout');
    });
}
function reloadGrid(){ 
    grid = $('.grid').masonry('reloadItems');
    grid.imagesLoaded().progress( function() {
        grid.masonry('layout');
    });
}
function initFilter(){ 
    sortProjects(1);
    $('.filter-projects').each(function(){
        if(!$(this).hasClass('has-filter')){
            $(this).addClass('has-filter');
            $(this).click(function(){
                $('.filter-projects').removeClass('selected preselected');
                $(this).addClass('selected');
                $('#projectssearch').val('');

                $('.projects').hide(0);
                var filters = ''
                $('.filter-projects.selected').each(function(){
                    var filter = $(this).attr('data-filter');
                    var value = $(this).attr('data-value');
                    filters += '.' + filter + '-' + value;
                    var filterval = $(this).find('a').text();
                    var filtername = $(this).parent().parent().attr('data-filtername');
                    $('.filtertitle').html(filtername + ': ' + filterval);
                    
                });
                $('.projects' + filters).show(300);
                $('.only-all').hide(0);
                dvf.initLazyload();
            });
        }
    });
    if($('.preselected').length > 0){
        var filters = ''
        $('.filter-projects.preselected').each(function(){
            var filter = $(this).attr('data-filter');
            var value = $(this).attr('data-value');
            filters += '.' + filter + '-' + value;
            var filterval = $(this).find('a').text();
            var filtername = $(this).parent().parent().attr('data-filtername');
            $('.filtertitle').html(filtername + ': ' + filterval);
        });
        $('.projects').hide(0);
        $('.projects' + filters).show(300);
        dvf.initLazyload();
    }
    
    if(!$('.clear-filter').hasClass('has-function')){
        $('.clear-filter').addClass('has-function');
        $('.clear-filter').click(function(){
            $('.filter-projects').removeClass('selected preselected');
            $('.projects').show(300);
            $('.filtertitle').html('Projekte: Alle');
            $('.only-all').show(0);
        });
    }
}
function initSearch(){ 
    $('#projectssearch').keyup(function() {
        window.clearTimeout(searchtimeout);
        searchtimeout = window.setTimeout(function(){
            var src = $('#projectssearch').val();
            var filter = src.toUpperCase();
            $('.filterbar').removeClass('opened');
            $('.filterbutton').find('svg use').attr('xlink:href', '/dist/gui.svg#closed');
            
            if(src != ''){
                $('.filter-projects').removeClass('selected preselected');
                $('.projects').hide(0);
                $('.projects').each(function() {
                    $(this).find('h2');
                    txtValue = $(this).find('h2').text().toUpperCase();
                    if (txtValue.indexOf(filter) > -1) {
                      $(this).show();
                    }
                });
                $('.only-all').hide(0);
                dvf.initLazyload();
            } else {
                $('.projects').show(0);
            }
        },500);
        
    });
    
    if(!$('.filterbutton').hasClass('has-function')){
        $('.filterbutton').addClass('has-function');
        $('.filterbutton').click(function(){
            if($('.filterbar').hasClass('opened')){
                $('.filterbar').removeClass('opened');
                $(this).find('svg use').attr('xlink:href', '/dist/gui.svg#closed');
            } else {
                $('.filterbar').addClass('opened');
                $(this).find('svg use').attr('xlink:href', '/dist/gui.svg#opened');
            }
        });
    }
}

function initSlider() {
        $('.inline-slider .slider').bxSlider({
            /*auto: true,*/
            stopAutoOnClick: true,
            pager: ($(".inline-slider div").length > 1) ? true: false,
            adaptiveHeight:true,
            captions: true
        });
    }

function sortProjects(dir){
    /*
    wrapper = $('.archive-container');
    wrapper.find('.projects').sort(function(a, b) {
        if(dir > 0){
            return +a.getAttribute('data-year') < +b.getAttribute('data-year');
        } else {
            return +a.getAttribute('data-year') > +b.getAttribute('data-year');            
        }
    }).appendTo(wrapper);*/
    $('.archive-container').each(function(){
        $(this).find('.projects').sort(function(a, b) {
            if(dir > 0){
                return +a.getAttribute('data-year') < +b.getAttribute('data-year');
            } else {
                return +a.getAttribute('data-year') > +b.getAttribute('data-year');            
            }
        }).appendTo(this);
    });
}
function initUncrypt() {
    $('.add-uncrypt').each(function(){
        if(!$(this).hasClass('has-uncrypt')){
            $(this).addClass('has-uncrypt');
            $(this).mouseover(function(){
                var addr = '';
                var crypt = $(this).attr('data-crypt');
                var enc = $(this).attr('data-enc');
                enc = unescape(enc);
                for (c = 0; c < enc.length; ++c) {
                    addr += String.fromCharCode(enc.charCodeAt(c) - crypt);
                }
                $(this).attr('href', 'mailto:' + addr);
            });
        }
    });    
}

function initToggleNext(){ 
    $('.toggle-next').each(function(){
        if(!$(this).hasClass('has-toggle-next')){
            $(this).addClass('has-toggle-next')
            $(this).click(function(){
                var accordion = $(this).attr('data-accordion');
                var next = $(this).next();
                var that = $(this);
                if (next.is(':visible')) {
                    next.hide(300);
                    if($(that).hasClass('info')){
                        $(that).find('svg use').attr('xlink:href', '/dist/gui.svg#infoopen');
                    }
                    if($(that).hasClass('teambar')){
                        $(that).find('svg use').attr('xlink:href', '/dist/gui.svg#closed');
                    }
                } else {
                    if (accordion > 0) {
                      $(this).parent().parent().find('.toggle-next').each(function() {
                        $(this).next().hide();
                      });
                    }
                    if($(that).hasClass('info')){
                        $(that).find('svg use').attr('xlink:href', '/dist/gui.svg#infoclosed');
                    }
                    if($(that).hasClass('teambar')){
                        $(that).find('svg use').attr('xlink:href', '/dist/gui.svg#opened');
                    }
                    next.show(300, function() {
                      $('html, body').animate({
                        scrollTop: $(that).offset().top - 85
                      }, 500);
                    });
                    dvf.initLazyload();
                  }
                /*
                var accordion = $(this).attr('data-accordion');
                var next = $(this).next();
                if(next.is(':visible')){
                    next.hide(300);
                } else {
                    if(accordion > 0){
                        $('.toggle-next').each(function(){
                            $(this).next().hide();
                        });
                    }
                    next.show(300);
                    dvf.initLazyload();
                }*/
            });
        }
    });
}
function initMobileHover(){
    var moving = false;
    $("body").on("touchstart", function(){
        moving = false;
    });
    $('.mobile-hover').on('touchmove', function(e) {
        moving = true;
    });
    $('.mobile-hover').on('touchend', function(e) {
        console.log('touchend');
        'use strict';
        var link = $(this);
        if(moving){
            console.log('moved');
            e.preventDefault();
            return false
        } else {
            if (link.hasClass('simulate-hover')) {
                console.log('in hover state');
                return true;
            } else {
                console.log('not hover state');
                $('.mobile-hover').removeClass('simulate-hover');
                link.addClass('simulate-hover');
                e.preventDefault();
                return false; 
            }
        }
    });
}
function initNews() {
    $('.add-paginator').each(function(){
        if(!$(this).hasClass('has-paginator')){
            $(this).addClass('has-paginator');
            $(this).click(function(){
                var offset = $('.grid').attr('data-offset');
                $.ajax({
                    type: "POST",
                    url: '/aktuell/getnews',
                    data: '&offset=' + offset,
                    success: function(content) {
                        $('.grid').append(content);
                        reloadGrid();
                        $('.grid').attr('data-offset', parseInt(offset)+3);
                        dvf.initLazyload();
                    }
                });
            });
        }
    });    
}

function initNewsSearch() {
    $('#newssearch').keyup(function() {
        window.clearTimeout(searchtimeout);
        searchtimeout = window.setTimeout(function(){
            var src = $('#newssearch').val();
            if(src != ''){
                $('.add-paginator').hide();
                $.ajax({
                        type: "POST",
                        url: '/aktuell/getnews',
                        data: '&search=' + src,
                        success: function(content) {
                            if(content)
                            $('.grid').html(content);
                            reloadGrid();
                            dvf.initLazyload();
                        }
                    });
            } else {
                $('.add-paginator').show();
                initNews();
            } 
        }, 500);
    });    
}

/*
function initGallery() {
    $('.gallery').first().show(0);
    $('.gallery').each(function(){
        if(!$(this).hasClass('has-gallery')){
            $(this).addClass('has-gallery');
            $(this).click(function(){
                var next = $(this).next('.gallery');
                if(next.length > 0){
                    next.show(0);
                } else {
                    console.log('FIRST');
                    $('.gallery').first().show(0);
                }
                $(this).hide(0);
                initLazyload();
            });
        }
    });    
}
*/